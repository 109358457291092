<template>
  <div v-if="item" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="item">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('pilatNr')"
            label-for="pilatNr"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="item.pilatNr" type="text" :placeholder="$t('pilatNr')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('wholesaleNr')"
            label-for="wholesaleNr"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="item.wholesaleNr" type="text" :placeholder="$t('wholesaleNr')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('industryNr')"
            label-for="industryNr"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="item.industryNr" type="text" :placeholder="$t('industryNr')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('type')"
            label-for="type"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('type')"
              v-model="item.type"
              id="type"
              :options="getEnumValues('ItemType')"
              :allow-empty="false"
              :show-labels="false"
              deselectLabel
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('unit')"
            label-for="unit"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('unit')"
              v-model="item.unit"
              id="unit"
              :options="getEnumValues('ItemUnit')"
              :allow-empty="false"
              :show-labels="false"
              deselectLabel
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('price')"
            label-for="price"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber
              v-model="item.price"
              mode="currency"
              currency="EUR"
              locale="de-DE"
              :placeholder="$t('price')"
            />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('inventory')"
            label-for="inventory"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <InputNumber v-model="item.inventory" :placeholder="$t('count')" />
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Beschreibung</strong>
          </legend>

          <ckeditor
            :editor="editor"
            v-model="item.description"
            :config="editorConfig"
            @ready="onEditorReady"
          ></ckeditor>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showPreviousButton="buttons.showPreviousButton"
            :showNextButton="buttons.showNextButton"
            :showDeleteButton="buttons.showDeleteButton"
            :model="item"
            modelEditRoute="ItemEditPage"
            modelListRoute="ItemsPage"
            modelRouteParamName="itemNumber"
            :nextModelNumber="item.nextItemNumber"
            :previousModelNumber="item.previousItemNumber"
            :updateCallback="updateItem"
            :createCallback="createItem"
            :deleteCallback="deleteItem"
            :fetchCallback="fetchItems"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import Multiselect from 'vue-multiselect';
import InputNumber from 'primevue/inputnumber';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'ItemEditPage',
  components: {
    Widget,
    Breadcrumbs,
    ckeditor: CKEditor.component,
    Multiselect,
    InputNumber,
    ButtonBar,
  },
  props: {
    itemNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      buttonsDisabled: false,
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters(['getItem', 'getItemByNumber', 'getEnumValues']),
    buttons() {
      return {
        showPreviousButton: this.item && this.item.previousItemNumber ? true : false,
        showNextButton: this.item && this.item.nextItemNumber ? true : false,
        showDeleteButton: this.item && this.item.number !== '_new' ? true : false,
        // showDeleteButton: false,
      };
    },
    optionsYesNo() {
      return yesNo();
    },
    item() {
      return this.getItemByNumber(this.number);
    },
    isNew() {
      return this.item._id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('item'), route: { name: 'ItemsPage' } },
        { name: this.item.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initItem',
      'fetchItems',
      'fetchItemByNumber',
      'updateItem',
      'createItem',
      'deleteItem',
      'fetchEnumValues',
    ]),
    onEditorReady(event) {},
    async setup() {
      await this.fetchEnumValues('ItemUnit');
      await this.fetchEnumValues('ItemType');
    },
  },
  async mounted() {
    await this.setup();
    if (!this.item && this.itemNumber) {
      this.number = this.itemNumber;
      this.fetchItemByNumber(this.number);
    } else {
      this.initItem();
      this.number = '_new';
    }
  },
  /**
   * if only parameters of the route have changed the component will be reused
   * we have to update all the data before the route updates
   * @param to
   * @param from
   * @param next
   */
  async beforeRouteUpdate(to, from, next) {
    // console.log('Route params', to.params);
    // console.log('Current project number', this.projectNumber);
    if (to.params.itemNumber && to.params.itemNumber !== this.itemNumber) {
      this.$store.commit('SET_ITEM_DEFAULT_STATE');
      this.number = to.params.itemNumber;
      await this.fetchItemByNumber(this.number);
      await this.setup();
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style scoped lang="scss"></style>
